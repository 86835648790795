<template>
  <div class="container" :style="{  background:bg}">
    <el-image @click="skipPage({name:'home', text:'首页'})" class="logo" src="/static/img/logo.png?v=2" alt="Logo"></el-image>
    <div>
      <ul class="navbar">
        <li @click="skipPage(tab)" v-for="tab in tabs" :class="{actived:tab.name==page}" :key="tab.name">{{tab.text}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      tabs: [
        // {
        //   name: "home",
        //   text: "首页",
        // },
        {
          name: "about",
          text: "我们",
        },
        {
          name: "textbook",
          text: "课程",
        },
        {
          name: "online_course",
          text: "网课",
        },
        {
          name: "news",
          text: "新闻",
        },
        {
          name: "activity",
          text: "大赛",
        },
        {
          name: "school",
          text: "校区",
        },
        {
          name: "league",
          text: "加盟",
        },
        {
          name: "download",
          text: "软件",
        },
      ],
      bg: "none",
      page: "home",
    };
  },
  watch: {
    $route(to) {
      this.page = to.path.split("/").pop() || "home";
    },
  },
  created() {
    this.page = this.$route.path.split("/").pop() || "home";
  },
  mounted() {
    // console.log(123);
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    skipPage(tab) {
      if (tab.name == this.page) {
        return;
      }
      this.$router.replace({
        path: "/" + tab.name,
      });
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      // });
    },
    handleScroll() {
      if (document.documentElement.scrollTop > 0) {
        this.bg = "#ff6f1b";
      } else {
        this.bg = "none";
      }
    },
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  height: 80px;
  //宽度适配调整的地方
  padding: 0 130px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: 1s;

  // background-color: #000;

  .logo {
    width: 113px;
    height: 38px;

    cursor: pointer;

    transition: 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }
  .navbar {
    display: flex;
    list-style: none;

    li {
      font-size: 16px;

      // font-weight: bold;
      color: rgba($color: #fff, $alpha: 0.8);
      // color: rgba($color: #fff, $alpha: 0.55);
      // margin-right: 50px;
      margin-right: 35px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        // font-weight: bold;
        // text-decoration: underline;
        color: rgba($color: #fff, $alpha: 1);
      }

      &.actived {
        color: #ffffff;
        cursor: default;
        position: relative;

        &::after {
          position: absolute;
          background: #fff;
          content: " ";
          height: 2px;
          left: 5px;
          right: 5px;
          bottom: -10px;
        }
      }
    }
  }
}
</style>

